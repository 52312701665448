@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light !important;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  color: black;
  /* width: 100vw; */
}

input,
select,
textarea {
  background-color: white;
}
