@layer components {
  .underlined::after {
    content: '';
    display: block;
    position: absolute;
    height: 2px;
    left: -2px;
    right: -2px;
    bottom: 0px;
    @apply bg-gray-600 rounded transition-colors duration-300;
  }

  .underlined.hover:hover::after {
    @apply bg-primary;
  }

  .underlined-sub {
    &::after {
      bottom: 2px;
      z-index: -2;
    }

    &::before {
      z-index: -1;
      content: '';
      display: block;
      position: absolute;
      height: 10px;
      width: 30px;
      left: 60%;
      bottom: -5px;
      @apply bg-white;
    }

    @media screen and (max-width: 639px) {
      &::before {
        width: 24px;
      }
    }
  }

  .about-text {
    @apply text-xl mt-5 text-gray-700;
  }
}
