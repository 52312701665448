@layer components {
  .form {
    input:focus-within ~ label,
    input:not(:placeholder-shown) ~ label {
      @apply transform scale-95 -translate-y-3 -translate-x-1;
    }

    input:invalid:not(:placeholder-shown) {
      @apply border-red-500;

      & ~ label {
        @apply text-red-500;
      }
    }

    input:valid:not(:placeholder-shown) {
      @apply border-primary-dark;

      & ~ label {
        @apply text-primary-dark;
      }
    }
  }
}
