@layer components {
  .fadeIn {
    opacity: 1;
  }

  .fadeOut {
    opacity: 0;
  }

  .nav-link {
    @apply relative cursor-pointer transition-all duration-300 text-lg lg:text-sm xl:text-base;

    &::after {
      content: '';
      @apply bg-primary block absolute rounded-full;
      height: 2px;
      left: -5%;
      right: 105%;
      top: 115%;
    }

    &.active {
      // @apply text-2xl lg:text-base xl:text-lg;
      &::after {
        right: -5%;
      }
    }

    svg {
      @apply hidden lg:inline-block transition-transform duration-1000;
    }
  }

  a > .nav-link {
    &:hover {
      &::after {
        right: -5%;
        transition: right 0.3s ease-in-out;
      }
    }
  }

  .nav-link.focus {
    svg {
      @apply transform rotate-180;
    }

    & + .nav-dropdown {
      @apply delay-300 max-h-96;
    }
  }

  a:hover {
    & > .nav-link svg {
      @apply transform rotate-180;
    }

    & > .nav-dropdown,
    .nav-dropdown:hover {
      @apply delay-300 max-h-96;
    }
  }

  .nav-dropdown {
    @apply hidden top-full whitespace-nowrap lg:block w-80 text-center pt-4 lg:pt-0 lg:text-left lg:absolute lg:-left-4 bg-white lg:shadow-md overflow-hidden max-h-0 transition-all duration-500 delay-0;

    .nav-dropdown-entry,
    .nav-dropdown-subentry {
      @apply px-6 py-2 block hover:bg-primary-light;
    }

    .nav-dropdown-subentry {
      @apply pl-16;
    }
  }
}
