@layer components {
  .imageShadow-lr {
    background: linear-gradient(
      to right,
      white 5%,
      rgba(255, 255, 255, 0.42) 15%,
      transparent 25%,
      transparent 75%,
      rgba(255, 255, 255, 0.42) 85%,
      white 95%
    );
  }

  .imageShadow-tb {
    background: linear-gradient(
      to bottom,
      white 5%,
      rgba(255, 255, 255, 0.42) 15%,
      transparent 25%,
      transparent 75%,
      rgba(255, 255, 255, 0.42) 85%,
      white 95%
    );
  }

  .text {
    @apply absolute font-semibold text-5xl 2xl:text-6xl;
    line-height: 1;
  }

  .heading {
    @apply inline sm:block text-2xl sm:text-5xl lg:text-4xl xl:text-5xl max:text-6xl font-bold;
  }

  .indicator {
    @apply absolute h-3 w-3 bg-primary rounded-full animate-scroller;
    top: 6px;
    left: 6px;
  }
}
